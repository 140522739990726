import React from "react";
import { Modal } from "antd";
import "./index.less";

// new 奖励弹窗
const RewardModel = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const { award } = props;
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  return (
    <Modal
      title={null}
      visible={visible}
      closable={false}
      footer={null}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      className="ap_modal_reward"
    >
      <div className="ap_modal1">
        <div
          className="ap_modal_header1"
          style={{
            backgroundImage: `url(${require("../../../../assets/bg_popUp_top@2x.png")})`,
          }}
        >
          <span className="title">恭喜您获得</span>
          <img
            className="icon_close"
            onClick={() => {
              setVisible(false);
            }}
            src={require("../../../../assets/sys_btn_delete_list@2x copy.png")}
            alt=""
          />
        </div>
        <div className="ap_modal_body1" style={{ borderBottom: "none" }}>
          <div className="reward">
            <div className="reward_box">
              <img src={award?.goods_info?.icon_path} alt="" />
            </div>
            <span className="reward_title">
              {award?.goods_info?.name} 礼物 x{award?.goods_amount}
            </span>
          </div>
        </div>
        <div className="ap_modal_footer1">
          <div className="reward_box">
            <div
              className="btn_yes"
              onClick={() => {
                setVisible(false);
              }}
            >
              确定
            </div>
            <span className="label">奖励稍后发放至您的背包中</span>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default RewardModel;
