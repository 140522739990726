import React from "react";
import { Modal } from "antd";
import "./index.less";
import { baseUrl1, baseUrl2 } from "../../../../config";
import { message } from "antd";
import moment from "moment";
import axios from "axios";

// new 派对记录弹窗
const PartyModal = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pers, setPers] = React.useState(10);
  const [hasMore, setHaveMore] = React.useState(true);
  const loadMoreData = async () => {
    if (loading) return;
    // if (!hasMore) return;
    setLoading(true);
    try {
      const { data: res } = await axios({
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        url: `${baseUrl1}/api/v1/dinosaur_paradise/dinosaurList`,
      });
      if (res.status === 10000) {
        setData(data.concat(res?.data));
        if (!res?.has_next) {
          setHaveMore(false);
        }
        setPage(page + 1);
      } else {
        message.message(res?.message);
      }
    } catch (error) {
      message.message("网络错误，请检查网络设置");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (visible) loadMoreData();
    else setData([]);
  }, [visible]);

  return (
    <Modal
      title={null}
      visible={visible}
      closable={false}
      footer={null}
      onCancel={close}
      className="ap_modal_party"
    >
      <div className="ap_modal2">
        <div className="ap_modal_box2">
          <div
            className="ap_modal_header2"
            style={{
              backgroundImage: `url(${require("../../../../assets/bg_popUp_top@2x.png")})`,
            }}
          >
            <span className="title">派对记录</span>
          </div>
          <div className="ap_modal_body2">
            <div className="party">
              <div className="party_th">
                <div className="item">进场时间</div>
                <div className="item">进场动物</div>
                <div className="item">所需午餐</div>
              </div>
              <ul className="party_list">
                {data &&
                  data?.length > 0 &&
                  data.map((item) => (
                    <li className="party_box" key={"party_" + item?.id}>
                      <div className="party_item">
                        <div className="time">
                          {moment(item?.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                        <div className={`animals ${item?.category === 1 ? "" : ""}`}>
                          <img className="animal-img" src={item?.dinosaur_info.icon} alt="" />
                          <span className="animal-name">
                            {item?.dinosaur_info.name}
                          </span>
                        </div>
                        <div className="foods">
                          {item?.category === 0 ? (
                            <img
                                className="foods_img"
                              src={item?.dinosaur_info.fear_turret_info.icon}
                              alt=""
                            />
                          ) : (
                            <img
                              className="foods_img"
                              src={require("../../../../assets/images/game/foods_list.png")}
                              alt=""
                            />
                          )}
                          <span className="animal-name">
                            {item?.category === 0
                              ? item?.dinosaur_info.fear_turret_info.name
                              : "所有"}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <span className="label">仅展示100局的数据哦～</span>
      </div>
    </Modal>
  );
});

export default PartyModal;
